import React, { useState, useEffect } from "react";
import $ from 'jquery';
import './About.css';

const About = () => {
    const [opis, setOpis] = useState(''); // State to hold the fetched `opis` value
    const [error, setError] = useState(null); // State to handle errors
    const nazwa = 'o_nas'; // Set the `nazwa` parameter value for the fetch request

    useEffect(() => {
        // Function to fetch `opis` from the PHP file
        const fetchOpis = () => {
            $.ajax({
                url: `/php/getOpis.php?nazwa=${encodeURIComponent(nazwa)}`,
                method: 'GET',
                dataType: 'json',
                success: (data) => {
                    if (data.message) {
                        setError(data.message); // Set error message if returned
                    } else if (data.opis) {
                        setOpis(data.opis); // Set the `opis` value in state
                    }
                },
                error: (xhr, status, error) => {
                    setError(`An error occurred: ${error}`); // Handle fetch errors
                }
            });
        };

        fetchOpis(); // Call the fetch function
    }, [nazwa]); // Dependency array includes `nazwa`

    if (error) {
        return <div>Error: {error}</div>; // Error handling
    }

    return (
        <div id="about">
            <h1>o nas</h1>
            <p>{opis || "Loading..."}</p> {/* Display `opis` or a loading message */}
        </div>
    );
};

export default About;
