import React, { useState, useEffect } from "react";
import $ from 'jquery';
import './Quote.css';

const Quote = () => {
    const [lines, setLines] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchQuoteLines = () => {
            const lineNames = [
                'quote_1_line_1',
                'quote_1_line_2',
                'quote_1_line_3',
            ];

            const fetchedLines = {};
            let errorOccurred = false;

            lineNames.forEach((name) => {
                $.ajax({
                    url: `/php/getOpis.php?nazwa=${encodeURIComponent(name)}`,
                    method: 'GET',
                    dataType: 'json',
                    success: (data) => {
                        if (data.message) {
                            setError(data.message); // Set error message if returned
                            errorOccurred = true;
                        } else {
                            fetchedLines[name] = data.opis || ""; // Add the fetched line
                        }

                        // Update state after the last fetch
                        if (Object.keys(fetchedLines).length === lineNames.length && !errorOccurred) {
                            setLines(fetchedLines);
                        }
                    },
                    error: () => {
                        setError("An error occurred while fetching quote lines.");
                    },
                });
            });
        };

        fetchQuoteLines(); // Call the fetch function
    }, []);

    if (error) {
        return <div>Error: {error}</div>; // Display error message if any
    }

    return (
        <div id="quote">
            <div id="quote-text">
                <h1>{lines['quote_1_line_1'] || "Loading..."}</h1>
                <h1><b>{lines['quote_1_line_2'] || "Loading..."}</b></h1>
                <h1><b>{lines['quote_1_line_3'] || "Loading..."}</b></h1>
            </div>
        </div>
    );
};

export default Quote;
