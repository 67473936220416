import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faFacebookF, faInstagram  } from '@fortawesome/free-brands-svg-icons';
import './NavBar.css';


const NavBar = ({scrollToGallery, closeModal, openContactModal}) => {

    useEffect(() => {
        setTimeout(() => {
            $('#nav-bar').addClass('visible');
        }, 100);
    }, []);

    const navigate = useNavigate();

    const scrollToAboutUs = () => {
        // Programmatically navigate to the homepage
        navigate(`/`);

        // Wait for a short delay before scrolling to ensure the navigation completes
        setTimeout(() => {
        // Use jQuery to scroll to the gallery section
        $('html, body').animate({
            scrollTop: $('#about').offset().top - 50
        }, 1000); // Adjust the duration of the animation as needed
        }, 100); // Adjust the delay as needed
    };

    const scrollToContact = () => {
        // Programmatically navigate to the homepage
        navigate(`/`);

        // Wait for a short delay before scrolling to ensure the navigation completes
        setTimeout(() => {
        // Use jQuery to scroll to the gallery section
        $('html, body').animate({
            scrollTop: $('#contact').offset().top - 50
        }, 1000); // Adjust the duration of the animation as needed
        }, 100); // Adjust the delay as needed
    };




    return (
        <div id='nav-bar'>
            <div id="nav-links">
                <span className="nav-link" onClick={scrollToAboutUs}>O NAS</span>
                <Link to='/produkty' className="nav-link"><span >MEBLE</span></Link>
                <span className="nav-link" onClick={scrollToContact}>KONTAKT</span>            
            </div>

            <div id="nav-icons">
                    <div id='icon-links'>
                        <a target="blank" href="https://www.instagram.com/olmomeble/"><FontAwesomeIcon className="#fontAwesomeIcon"  icon={faInstagram} /></a>
                        <a target="blank" href="https://www.facebook.com/olmo.meble"><FontAwesomeIcon className="#fontAwesomeIcon" icon={faFacebookF} /></a>
                        <a target="blank" href="https://www.tiktok.com/olmo" ><FontAwesomeIcon className="#fontAwesomeIcon"  icon={faTiktok} /></a>
                    </div>
                    
                    <div id='closeBtn' >
                        {/* <img className="rectangle-x" onClick={closeModal} src={`${process.env.PUBLIC_URL}/images/rectangle-x.svg`} alt="Close" /> */}
                        <svg id='closeButtonNavBar' onClick={closeModal} data-name="button menu off" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 36.104 36.438">
                            <path  data-name="Path 51" d="M18,0h.1a18,18,0,0,1,18,18v.438a18,18,0,0,1-18,18H18a18,18,0,0,1-18-18V18A18,18,0,0,1,18,0Z" fill="#eaeaeb"/>
                            <line  data-name="Line 32" x2="18.457" y2="17.717" transform="translate(8.824 9.36)" fill="none" stroke="#141314" stroke-linecap="round" stroke-width="3"/>
                            <line  data-name="Line 33" x1="18.457" y2="17.717" transform="translate(8.824 9.36)" fill="none" stroke="#141314" stroke-linecap="round" stroke-width="3"/>
                        </svg>

                    </div>
                    
            </div>
        </div>
    )
};

export default NavBar;